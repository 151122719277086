<template>
  <a-modal
    class="store-edit-modal"
    title="Редактирование филиалов"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <div v-show="!(!loading && !confirmLoading)" class="w-100 py-5 my-5 text-center">
      <a-spin tip="Загрузка..."></a-spin>
    </div>
    <a-form v-show="!loading && !confirmLoading" v-model="store" @submit.prevent="update">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <div class="logo-upload">
              <div v-if="!editLogo" class="ant-upload-picture-card-wrapper logo-uploader">
                <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
                  <span role="button" class="ant-upload">
                    <a-button @click="editLogo = true" type="link">
                      <EditOutlined />
                      Изменить лого
                    </a-button>
                  </span>
                </div>
              </div>
              <div v-else>
                <a-upload
                  name="logo_url"
                  list-type="picture-card"
                  class="logo-uploader"
                  :show-upload-list="false"
                  :headers="getHeaders()"
                  :action="getUrl"
                  :before-upload="beforeUpload"
                  @change="uploadLogo"
                >
                  <img v-if="logoUrl" :src="logoUrl" alt="avatar" />
                  <div v-else>
                    <loading-outlined v-if="loading"></loading-outlined>
                    <PlusOutlined v-else></PlusOutlined>
                    <div class="ant-upload-text">Логотип</div>
                  </div>
                </a-upload>
              </div>
              <div class="mb-4 text-center" v-if="logoUrl">
                <a-button @click="removeImage('logo_url')" type="link" danger><CloseCircleOutlined />Удалить логотип</a-button>
              </div>
            </div>
            <div class="logo-upload">
              <div v-if="!editBackground" class="ant-upload-picture-card-wrapper logo-uploader">
                <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
                  <span role="button" class="ant-upload">
                    <a-button @click="editBackground = true" type="link">
                      <EditOutlined />
                      Изменить фон
                    </a-button>
                  </span>
                </div>
              </div>
              <div v-else>
                <a-upload
                  name="background_url"
                  list-type="picture-card"
                  class="logo-uploader"
                  :show-upload-list="false"
                  :headers="getHeaders()"
                  :action="getUrl"
                  :before-upload="beforeUpload"
                  @change="uploadBackground"
                >
                  <img v-if="backgroundUrl" :src="backgroundUrl" alt="avatar" />
                  <div v-else>
                    <loading-outlined v-if="loading"></loading-outlined>
                    <PlusOutlined v-else></PlusOutlined>
                    <div class="ant-upload-text">Фон</div>
                  </div>
                </a-upload>
              </div>
            </div>
            <div class="mb-4 text-center" v-if="backgroundUrl">
              <a-button @click="removeImage('background_url')" type="link" danger><CloseCircleOutlined />Удалить фон</a-button>
            </div>
            <a-form-item label="НДС по умолчанию">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-input
                        :disabled="!editDefaultTax"
                        placeholder="НДС"
                        v-model:value="store.default_product_tax"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editDefaultTax = !editDefaultTax" type="link">
                        <CloseCircleOutlined v-if="editDefaultTax" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Оплата за счет">
              <a-switch
                checked-children="Вкл"
                un-checked-children="Выкл"
                v-model:checked="store.pay_for_bill" />
            </a-form-item>
            <div class="code-box-meta markdown" v-if="store.pay_for_bill">
              <div class="code-box-title">
                <span>Реквизиты оплаты</span>
              </div>
              <a-form-item label="ИНН (ПИНФЛ для ИП)">
                <a-input
                  v-if="recipientAccount"
                  disabled
                  class="w-100"
                  v-model:value="store.tin"
                />
                <a-input
                  v-else
                  class="w-100"
                  v-model:value="store.tin"
                />
              </a-form-item>
              <a-form-item label="МФО">
                <a-input
                  v-if="recipientAccount"
                  disabled
                  class="w-100"
                  v-model:value="store.mfo"
                />
                <a-input
                  v-else
                  class="w-100"
                  v-model:value="store.mfo"
                />
              </a-form-item>
              <a-form-item label="Расч. счет">
                <a-input
                  v-if="recipientAccount"
                  disabled
                  class="w-100"
                  v-model:value="store.account_no"
                />
                <a-input
                  v-else
                  class="w-100"
                  v-model:value="store.account_no"
                />
              </a-form-item>
              <a-form-item v-if="companyName" label="Название орг.">
                <a-input
                  v-if="companyName"
                  disabled
                  class="w-100"
                  v-model:value="companyName"
                />
              </a-form-item>
              <a-form-item v-if="companyStatus" label="Статус">
                <a-input
                  v-if="companyStatus"
                  disabled
                  class="w-100"
                  v-model:value="companyStatus"
                />
              </a-form-item>
            </div>
          </div>
          <div class="col-md-6">
            <a-form-item label="Название филиала">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-input
                        :disabled="!editName"
                        placeholder="Введите название филиала"
                        v-model:value="store.name"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editName = !editName" type="link">
                        <CloseCircleOutlined v-if="editName" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Бренд">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-select
                        :disabled="!editBrand"
                        ref="brand_id"
                        v-model:value="store.brand_id"
                        :options="brands"
                        :option-filter-prop="'label'"
                        show-search
                        allow-clear
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editBrand = !editBrand" type="link">
                        <CloseCircleOutlined v-if="editBrand" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Варианты оплаты">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-select
                        :disabled="!editPaymentOptions"
                        v-model:value="store.payment_options"
                        mode="tags"
                        :max-tag-count="4"
                        placeholder="Варианты оплаты"
                        allow-clear
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editPaymentOptions = !editPaymentOptions" type="link">
                        <CloseCircleOutlined v-if="editPaymentOptions" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Варианты чаевых">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-select
                        :disabled="!editTipsOptions"
                        v-model:value="store.tips_options"
                        mode="tags"
                        :max-tag-count="4"
                        placeholder="Варианты чаевых"
                        allow-clear
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editTipsOptions = !editTipsOptions" type="link">
                        <CloseCircleOutlined v-if="editTipsOptions" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Вариант по умолчанию">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-radio-group
                        :disabled="!editDefaultOption"
                        v-model:value="store.default_option"
                        >
                        <a-radio-button
                          v-for="key in store.payment_options.length"
                          :value="key"
                          :key="key"
                        >{{ store.payment_options[key - 1] }}</a-radio-button>
                      </a-radio-group>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editDefaultOption = !editDefaultOption" type="link">
                        <CloseCircleOutlined v-if="editDefaultOption" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Настройки доставки">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-select
                        :disabled="!editDeliverySettings"
                        ref="split"
                        v-model:value="store.split"
                      >
                        <a-select-option value="with_payment">Оплата с доставкой</a-select-option>
                        <a-select-option value="split_delivery">Доставка отдельно</a-select-option>
                        <a-select-option value="same_page">На одной странице</a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editDeliverySettings = !editDeliverySettings" type="link">
                        <CloseCircleOutlined v-if="editDeliverySettings" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Тип кошелька">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-select
                        :disabled="!editWalletType"
                        ref="wallet_type"
                        v-model:value="store.wallet_type"
                      >
                        <a-select-option value="personal">Личный</a-select-option>
                        <a-select-option value="shared">Общий</a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editWalletType = !editWalletType" type="link">
                        <CloseCircleOutlined v-if="editWalletType" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Комиссия за чаевые" style="position: relative">
              <a-input
                placeholder="Введите комиссию"
                suffix="%"
                type="number"
                v-model:value="store.tips_commission"
                :disabled="!tipsCommissionEnabled"
              />
              <a-button
                @click="tipsCommissionEnabled = !tipsCommissionEnabled"
                type="text"
                style="position: absolute; right: 0"
              >
                <template #icon>
                  <EditOutlined v-if="!tipsCommissionEnabled" />
                  <CloseOutlined v-if="tipsCommissionEnabled" />
                </template>
              </a-button>
            </a-form-item>
            <a-form-item label="Тип кассовой системы">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-select
                        :disabled="!editPos"
                        ref="pos_type_id"
                        v-model:value="store.pos_type_id"
                        :options="posTypes"
                        allow-clear
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editPos = !editPos" type="link">
                        <CloseCircleOutlined v-if="editPos" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Отзывы" style="position: relative">
              <a-input
                @change="fetchChannelName($event, 'tips')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_tips"
                :disabled="!tipsLoginEnabled"
              />
              <a-button
                @click="tipsLoginEnabled = !tipsLoginEnabled"
                type="text"
                style="position: absolute; right: 0"
              >
                <template #icon>
                  <EditOutlined v-if="!tipsLoginEnabled" />
                  <CloseOutlined v-if="tipsLoginEnabled" />
                </template>
              </a-button>
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="tipsChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px' }">{{ tipsChannelName }}</a-typography-text>
            </a-form-item>
            <a-form-item label="Счета" style="position: relative;">
              <a-input
                @change="fetchChannelName($event, 'bill')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_bill"
                :disabled="!billLoginEnabled"
              />
              <a-button
                @click="billLoginEnabled = !billLoginEnabled"
                type="text"
                style="position: absolute; right: 0"
              >
                <template #icon>
                  <EditOutlined v-if="!billLoginEnabled" />
                  <CloseOutlined v-if="billLoginEnabled" />
                </template>
              </a-button>
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="billChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px'} ">{{ billChannelName }}</a-typography-text>
            </a-form-item>
            <a-form-item label="Бронирование" style="position: relative;">
              <a-input
                @change="fetchChannelName($event, 'reserve')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_reserve"
                :disabled="!reserveLoginEnabled"
              />
              <a-button
                @click="reserveLoginEnabled = !reserveLoginEnabled"
                type="text"
                style="position: absolute; right: 0"
              >
                <template #icon>
                  <EditOutlined v-if="!reserveLoginEnabled" />
                  <CloseOutlined v-if="reserveLoginEnabled" />
                </template>
              </a-button>
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="reserveChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px'} ">{{ reserveChannelName }}</a-typography-text>
            </a-form-item>
            <a-form-item label="Заказы" style="position: relative;">
              <a-input
                @change="fetchChannelName($event, 'order')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_order"
                :disabled="!orderLoginEnabled"
              />
              <a-button
                @click="orderLoginEnabled = !orderLoginEnabled"
                type="text"
                style="position: absolute; right: 0"
              >
                <template #icon>
                  <EditOutlined v-if="!orderLoginEnabled" />
                  <CloseOutlined v-if="orderLoginEnabled" />
                </template>
              </a-button>
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="orderChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px'} ">{{ orderChannelName }}</a-typography-text>
            </a-form-item>
            <a-form-item label="Доставка" style="position: relative;">
              <a-input
                @change="fetchChannelName($event, 'delivery')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_delivery"
                :disabled="!deliveryLoginEnabled"
              />
              <a-button
                @click="deliveryLoginEnabled = !deliveryLoginEnabled"
                type="text"
                style="position: absolute; right: 0"
              >
                <template #icon>
                  <EditOutlined v-if="!deliveryLoginEnabled" />
                  <CloseOutlined v-if="deliveryLoginEnabled" />
                </template>
              </a-button>
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="deliveryChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px'} ">{{ deliveryChannelName }}</a-typography-text>
            </a-form-item>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons-vue';
import {reactive, ref} from 'vue';
import { getBase64 } from "@/helpers/base64";
import { getHeaders } from "@/helpers/header"
import apiClient from "@/services/axios";
import {message, notification} from "ant-design-vue";
import Store from "@/models/Store";
import {debounce} from "lodash";
import vuexstore from "store";

const props = defineProps({
  storeIds: {
    type: Array,
    default: null,
  },
  posTypes: {
    type: Array,
    default: ref([]),
  },
  brands: {
    type: Array,
    default: ref([]),
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let logoUrl = ref(''),
  backgroundUrl = ref(''),
  editLogo = ref(false),
  editBackground = ref(false),
  editName = ref(false),
  editBrand = ref(false),
  editPaymentOptions = ref(false),
  editTipsOptions = ref(false),
  editDefaultOption = ref(false),
  editDeliverySettings = ref(false),
  editWalletType = ref(false),
  editPos = ref(false),
  editDefaultTax = ref(false),
  loading = ref(false),
  tipsLoginEnabled = ref(false),
  tipsCommissionEnabled = ref(false),
  billLoginEnabled = ref(false),
  reserveLoginEnabled = ref(false),
  orderLoginEnabled = ref(false),
  deliveryLoginEnabled = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  recipientAccount = ref(null),
  companyName = ref(null),
  companyStatus = ref(null),
  phone = ref(''),
  editDefaultSpic = ref(false),
  editDefaultCode = ref(false),
  spicFetching = ref(false),
  codeFetching = ref(false),
  tipsChannelFetching = ref(false),
  tipsChannelName = ref(''),
  billChannelFetching = ref(false),
  billChannelName = ref(''),
  reserveChannelFetching = ref(false),
  reserveChannelName = ref(''),
  orderChannelFetching = ref(false),
  orderChannelName = ref(''),
  deliveryChannelFetching = ref(false),
  deliveryChannelName = ref(''),
  spicData = ref([]),
  codeData = ref([]),
  spicModel = ref([]),
  codeModel = ref([]),
  lastFetchId = 0,
  store = reactive(new Store({}));

const
  uploadLogo = info => {
    logoUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      store.logo_url = info.file.response.data
      getBase64(info.file['originFileObj'], base64Url => {
        logoUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  uploadBackground = info => {
    backgroundUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      store.background_url = info.file.response.data
      getBase64(info.file['originFileObj'], base64Url => {
        backgroundUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  removeImage = (type) => {
    store[type] = ''
    if (type === 'logo_url')
      logoUrl.value = ''
    else
      backgroundUrl.value = ''

    confirmLoading.value = true;
    apiClient
      .post(`/store/remove-images?type=${type}`, {
        ids: props.storeIds
      })
      .then(response => {
        if (response) {
          confirmLoading.value = false;
        }
      }).catch(() => {
      confirmLoading.value = false;
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  beforeUpload = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/store/upload'
  },
  resetForm = () => {
    Object.assign(store, new Store({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  clearRecipientAccount = () => {
    // clearLoading.value = true
    // apiClient
    //   .post(`/store/${props.storeId}/clear-recipient-account`)
    //   .then(response => {
    //     if (response.data.data) {
    //       recipientAccount.value = null
    //       store.recipient_account = null
    //       store.tin = null
    //       store.account_no = null
    //       store.mfo = null
    //     }
    //     clearLoading.value = false
    //   }).catch(err => {
    //   console.log(err)
    //   clearLoading.value = false
    // }).finally(() => clearLoading.value = false)
  },
  fetchChannelName = debounce((event, type) => {
    switch (type) {
      case 'tips':
        tipsChannelFetching.value = true;
        break;
      case 'bill':
        billChannelFetching.value = true;
        break;
      case 'reserve':
        reserveChannelFetching.value = true;
        break;
      case 'order':
        orderChannelFetching.value = true;
        break;
      case 'delivery':
        deliveryChannelFetching.value = true;
        break;
    }

    fetch(`https://api.telegram.org/bot1886153513:AAFI8UfPx4bnD1FSwWWwolQYhlnrRvRm74Y/getChat?chat_id=${event.target.value}`)
      .then(response => response.json())
      .then((res) => {
        switch (type) {
          case 'tips':
            tipsChannelFetching.value = false
            tipsChannelName.value = (res.ok) ? res.result.title : ''
            break;
          case 'bill':
            billChannelFetching.value = false
            billChannelName.value = (res.ok) ? res.result.title : ''
            break;
          case 'reserve':
            reserveChannelFetching.value = false
            reserveChannelName.value = (res.ok) ? res.result.title : ''
            break;
          case 'order':
            orderChannelFetching.value = false
            orderChannelName.value = (res.ok) ? res.result.title : ''
            break;
          case 'delivery':
            deliveryChannelFetching.value = false
            deliveryChannelName.value = (res.ok) ? res.result.title : ''
            break;
        }
      })
  }, 300),
  fetchSpic = debounce((value) => {
    // if (value.length < 17)
    //   return false;

    lastFetchId += 1;
    const fetchId = lastFetchId;
    spicData.value = [];
    spicFetching.value = true;
    const accessToken = vuexstore.get('accessToken')
    const apiUrl = process.env.VUE_APP_BACKEND_URL;
    fetch(`${apiUrl}/cabinet/menu/fetch-spic?product=&value=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": `Bearer ${accessToken}`,
      },
    })
      .then(response => response.json())
      .then(({data}) => {
        if (fetchId !== lastFetchId) {
          // for fetch callback order
          return;
        }
        spicData.value = data;
        spicFetching.value = false;
      });
  }, 300),
  fetchCode = debounce((value) => {
    // if (value.length < 17)
    //   return false;

    lastFetchId += 1;
    const fetchId = lastFetchId;
    codeData.value = [];
    codeFetching.value = true;
    const accessToken = vuexstore.get('accessToken')
    const apiUrl = process.env.VUE_APP_BACKEND_URL;
    fetch(`${apiUrl}/cabinet/menu/fetch-code?product=&spic=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": `Bearer ${accessToken}`,
      },
    })
      .then(response => response.json())
      .then(({data}) => {
        if (fetchId !== lastFetchId) {
          // for fetch callback order
          return;
        }
        codeData.value = data;
        codeFetching.value = false;
      });
  }, 300),
  saveSpic = () => {
    let model
    if (spicModel.value) {
      if (spicModel.value.length) {
        model = spicData.value.find((spic) => spic?.value === spicModel.value.find(() => true))
      } else {
        model = spicData.value.find((spic) => spic?.value === spicModel?.value)
      }
    }
    if (!model)
      model = 'null'

    store.default_product_spic = model?.code
    editDefaultSpic.value = false
    fetchCode(model?.value)
  },
  saveCode = () => {
    let model
    if (codeModel.value) {
      if (codeModel.value.length) {
        model = codeData.value.find((code) => code?.value === codeModel.value.find(() => true))
      } else {
        model = codeData.value.find((code) => code?.value === codeModel?.value)
      }
    }
    if (!model)
      model = 'null'

    store.default_product_code = model?.code
    editDefaultCode.value = false
  },
  update = () => {
    confirmLoading.value = true;

    /*
    let model = toRaw(store);
    model.payment_options = JSON.stringify(model.payment_options);
    model.default_option = model.default_option - 1;
    model.ask_mode = model.ask_mode ? 1 : 0;
    model.tax = model.tax ? 1 : 0;
     */

    let model = {}
    if (editLogo.value) model.logo_url = store.logo_url
    if (editBackground.value) model.background_url = store.background_url
    if (editName.value) model.name = store['name']
    if (editBrand.value) model.brand_id = store.brand_id
    if (editPaymentOptions.value) model.payment_options = JSON.stringify(store.payment_options)
    if (editTipsOptions.value) model.tips_options = JSON.stringify(store.tips_options)
    if (editDefaultOption.value) model.default_option = store.default_option - 1
    if (editDeliverySettings.value) model.split = store['split']
    if (editWalletType.value) model.wallet_type = store['wallet_type']
    if (tipsCommissionEnabled.value) model.tips_commission = store['tips_commission']
    if (editPos.value) model.pos_type_id = store.pos_type_id
    if (tipsLoginEnabled.value) model.login_for_tips = store['login_for_tips']
    if (billLoginEnabled.value) model.login_for_bill = store['login_for_bill']
    if (reserveLoginEnabled.value) model.login_for_reserve = store['login_for_reserve']
    if (orderLoginEnabled.value) model.login_for_order = store['login_for_order']
    if (deliveryLoginEnabled.value) model.login_for_delivery = store['login_for_delivery']

    return apiClient.put(`/store/update-all`, {
      ...model,
      ids: props.storeIds,
    }).then(response => {
      if (response) {
        resetForm()
        if (response.data.data) {
          response.data.data.forEach((item) => {
            notification.warning({
              message: `ID: ${item.id}`,
              description: item.details,
            })
          })
        }
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  };

</script>

<style scoped>
@import "./edit.css";
</style>
