<template>
  <a-modal
    class="store-edit-modal"
    title="Настройки филиалов"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <div v-show="!(!loading && !confirmLoading)" class="w-100 py-5 my-5 text-center">
      <a-spin tip="Загрузка..."></a-spin>
    </div>
    <a-form v-show="!loading && !confirmLoading" v-model="store" @submit.prevent="update">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <a-form-item label="Номер телефона">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-input
                        :disabled="!editPhone"
                        @input="updatePhone($event.target.value)"
                        v-model:value="phone"
                        v-maska="'+998(##) ###-##-##'"
                        placeholder="Введите номер телефона"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editPhone = !editPhone" type="link">
                        <CloseCircleOutlined v-if="editPhone" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Telegram">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-input
                        :disabled="!editTelegram"
                        placeholder="Пр: rahmatuz"
                        v-model:value="store.telegram"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editTelegram = !editTelegram" type="link">
                        <CloseCircleOutlined v-if="editTelegram" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Instagram">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-input
                        :disabled="!editInstagram"
                        placeholder="Пр: rahmatuz"
                        v-model:value="store.instagram"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editInstagram = !editInstagram" type="link">
                        <CloseCircleOutlined v-if="editInstagram" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Средний чек / Цена за dl. для AliveWater">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-input
                        :disabled="!editAvgBill"
                        v-model:value="store.avg_bill"
                        placeholder="Средний чек"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editAvgBill = !editAvgBill" type="link">
                        <CloseCircleOutlined v-if="editAvgBill" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Описание">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-textarea
                        :disabled="!editDescription"
                        v-model:value="store.description"
                        placeholder="Описание"
                        :auto-size="{ minRows: 2, maxRows: 6 }"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editDescription = !editDescription" type="link">
                        <CloseCircleOutlined v-if="editDescription" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Цвет">
              <div v-if="!editButtonColor">
                <a-button class="w-100" @click="editButtonColor = true">Редактировать</a-button>
              </div>
              <color-picker
                v-if="editButtonColor"
                @color-change="onChangeButtonColor"
                :color="getColor()"
                alpha-channel="hide"
                :visible-formats="['hex']"
              />
              <a-button
                type="primary"
                v-if="editButtonColor"
                @click="resetColor"
                class="w-100"
              >Сбросить
              </a-button>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Кухня и блюда">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-select
                        :disabled="!editCuisines"
                        v-model:value="store.cuisines"
                        mode="tags"
                        :options="cuisines"
                        :option-filter-prop="'label'"
                        allow-clear
                        :max-tag-count="4"
                        placeholder="Кухня и блюда"
                      >
                        <template #option="{ value, label, icon }">
                          <img width="20" :src="icon" :alt="value" />
                          &nbsp;&nbsp;{{ label }}
                        </template>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editCuisines = !editCuisines" type="link">
                        <CloseCircleOutlined v-if="editCuisines" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Тип заведения">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-select
                        :disabled="!editClassifications"
                        v-model:value="store.classifications"
                        mode="tags"
                        :options="classifications"
                        :option-filter-prop="'label'"
                        allow-clear
                        :max-tag-count="4"
                        placeholder="Типы заведений"
                      >
                        <template #option="{ label }">
                          &nbsp;&nbsp;{{ label }}
                        </template>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editClassifications = !editClassifications" type="link">
                        <CloseCircleOutlined v-if="editClassifications" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Дополнительные">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-select
                        :disabled="!editAdditionals"
                        v-model:value="store.additionals"
                        mode="tags"
                        :options="additionals"
                        :max-tag-count="4"
                        :option-filter-prop="'label'"
                        allow-clear
                        placeholder="Дополнительные"
                      >
                        <template #option="{ label }">
                          &nbsp;&nbsp;{{ label }}
                        </template>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editAdditionals = !editAdditionals" type="link">
                        <CloseCircleOutlined v-if="editAdditionals" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Стикеры">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-select
                        :disabled="!editStickers"
                        v-model:value="store.stickers"
                        mode="tags"
                        :options="stickers"
                        :max-tag-count="4"
                        :option-filter-prop="'label'"
                        placeholder="Стикеры"
                        allow-clear
                      >
                        <template #option="{ value, label, icon }">
                          <img width="20" :src="icon" :alt="value" />
                          &nbsp;&nbsp;{{ label }}
                        </template>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editStickers = !editStickers" type="link">
                        <CloseCircleOutlined v-if="editStickers" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Кешбек">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <a-input
                        :disabled="!editCashback"
                        placeholder="Введите кешбек"
                        suffix="%"
                        type="number"
                        v-model:value="store.cashback"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row justify-content-end">
                      <a-button @click="editCashback = !editCashback" type="link">
                        <CloseCircleOutlined v-if="editCashback" />
                        <EditOutlined v-else />
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <div class="d-flex justify-content-between" v-if="!editWorkingHours">
              <p>Часы работы:</p>
              <div class="d-flex justify-content-between">
                <a-button @click="editWorkingHours = true" type="link">
                  <EditOutlined />
                </a-button>
              </div>
            </div>
            <div class="d-flex justify-content-between" v-else>
              <p>Часы работы:</p>
              <div class="d-flex justify-content-between">
                <p class="mr-2">Кругл:</p>
                <a-switch
                  v-model:checked="store.day_n_night"
                  un-checked-children="Выкл"
                  checked-children="Вкл"
                >Кругл:
                </a-switch>
                <a-button @click="editWorkingHours = false" type="link">
                  <CloseCircleOutlined />
                </a-button>
              </div>
            </div>
            <a-form-item v-if="!store.day_n_night && editWorkingHours">
              <div class="container">
                <div class="row pb-3">
                  <div class="col-md-2">
                    <a-button type="text" class="px-0">ПН</a-button>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.mo_open"
                        placeholder="Открытия"
                        @change="openWorkHour"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row px-1">
                      <a-button type="text" class="px-4">до</a-button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.mo_close"
                        placeholder="Закрытия"
                        @change="closeWorkHour"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-md-2">
                    <a-button type="text" class="px-0">ВТ</a-button>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.tu_open"
                        placeholder="Открытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row px-1">
                      <a-button type="text" class="px-4">до</a-button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.tu_close"
                        placeholder="Закрытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-md-2">
                    <a-button type="text" class="px-0">СР</a-button>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.we_open"
                        placeholder="Открытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row px-1">
                      <a-button type="text" class="px-4">до</a-button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.we_close"
                        placeholder="Закрытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-md-2">
                    <a-button type="text" class="px-0">ЧТ</a-button>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.th_open"
                        placeholder="Открытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row px-1">
                      <a-button type="text" class="px-4">до</a-button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.th_close"
                        placeholder="Закрытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-md-2">
                    <a-button type="text" class="px-0">ПТ</a-button>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.fr_open"
                        placeholder="Открытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row px-1">
                      <a-button type="text" class="px-4">до</a-button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.fr_close"
                        placeholder="Закрытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-md-2">
                    <a-button type="text" class="px-0">СБ</a-button>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.sa_open"
                        placeholder="Открытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row px-1">
                      <a-button type="text" class="px-4">до</a-button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.sa_close"
                        placeholder="Закрытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-md-2">
                    <a-button type="text" class="px-0">ВС</a-button>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.su_open"
                        placeholder="Открытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row px-1">
                      <a-button type="text" class="px-4">до</a-button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.su_close"
                        placeholder="Закрытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <div class="code-box-meta markdown mt-5">
              <div class="code-box-title">
                <span>Дополнительные параметры</span>
              </div>
            </div>
            <div v-if="!editParameters">
              <a-button class="w-100" @click="editParameters = true">Редактировать</a-button>
            </div>
            <div class="col-md-12" v-else>
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Показать кнопку 'Позвать официанта'</div>
                      </template>
                      <a-form-item label="Официант">
                        <a-switch
                          un-checked-children="Скр."
                          checked-children="Пок."
                          v-model:checked="store.call_waitress" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Показать кнопку 'Позвать кальянщика'</div>
                      </template>
                      <a-form-item label="Кальянщик">
                        <a-switch
                          un-checked-children="Скр."
                          checked-children="Пок."
                          v-model:checked="store.call_hookah" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Показать кнопку 'Попросить счет'</div>
                      </template>
                      <a-form-item label="Счет">
                        <a-switch
                          un-checked-children="Скр."
                          checked-children="Пок."
                          v-model:checked="store.call_bill" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Проверка локации</div>
                      </template>
                      <a-form-item label="Локация">
                        <a-switch
                          un-checked-children="Выкл."
                          checked-children="Вкл."
                          v-model:checked="store.check_location" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Разрешить сканировать QR-код на столах</div>
                      </template>
                      <a-form-item label="QR за столом">
                        <a-switch
                          un-checked-children="Выкл."
                          checked-children="Вкл."
                          v-model:checked="store.scan_enabled" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Разрешить оплатить онлайн при заказе за столом</div>
                      </template>
                      <a-form-item label="Оплата за столом">
                        <a-switch
                          un-checked-children="Выкл."
                          checked-children="Вкл."
                          v-model:checked="store.with_payment" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Разрешить бронировать стол</div>
                      </template>
                      <a-form-item label="Брон">
                        <a-switch
                          un-checked-children="Выкл."
                          checked-children="Вкл."
                          v-model:checked="store.can_reserve" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Отображать филиал на странице маркетплейса</div>
                      </template>
                      <a-form-item label="Маркетплейс">
                        <a-switch
                          un-checked-children="Выкл."
                          checked-children="Вкл."
                          v-model:checked="store.show_marketplace" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Включить маркировку</div>
                      </template>
                      <a-form-item label="Маркировка">
                        <a-switch
                          un-checked-children="Выкл."
                          checked-children="Вкл."
                          v-model:checked="store.mark" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="editParameters">
              <a-button class="w-100" type="primary" @click="editParameters = false">Сбросить</a-button>
            </div>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {
  EditOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons-vue';
import {reactive, ref} from 'vue';
import apiClient from "@/services/axios";
import Store from "@/models/Store";
import {ColorPicker} from "vue-accessible-color-picker";
import {notification} from "ant-design-vue";

const props = defineProps({
  storeIds: {
    type: Array,
    default: null,
  },
  posTypes: {
    type: Array,
    default: ref([]),
  },
  brands: {
    type: Array,
    default: ref([]),
  },
  cuisines: {
    type: Array,
    default: ref([]),
  },
  classifications: {
    type: Array,
    default: ref([]),
  },
  additionals: {
    type: Array,
    default: ref([]),
  },
  stickers: {
    type: Array,
    default: ref([]),
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let
  editPhone = ref(false),
  editTelegram = ref(false),
  editInstagram = ref(false),
  editAvgBill = ref(false),
  editDescription = ref(false),
  editButtonColor = ref(false),
  editCuisines = ref(false),
  editClassifications = ref(false),
  editAdditionals = ref(false),
  editStickers = ref(false),
  editWorkingHours = ref(false),
  editCashback = ref(false),
  editParameters = ref(false),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  companyName = ref(null),
  phone = ref(''),
  codeData = ref([]),
  spicModel = ref([]),
  codeModel = ref([]),
  store = reactive(new Store({}));

const
  workingHours = ref([
    "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30",
    "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
    "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30",
    "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"
  ]),
  getColor = () => {
    return "#1fb7a4";
  },
  onChangeButtonColor = value => {
    store.button_color = value.cssColor;
  },
  resetColor = () => {
    editButtonColor.value = false;
    store.button_color = "";
  },
  resetForm = () => {
    Object.assign(store, new Store({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  updatePhone = (input) => {
    let x = input
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    phone.value = input;
    store.phone = x[0];
  },
  openWorkHour = (value) => {
    const open_times = [store.tu_open, store.we_open, store.th_open, store.fr_open, store.sa_open, store.su_open];
    if (!open_times.every(val => val)) {
      store.tu_open = value;
      store.we_open = value;
      store.th_open = value;
      store.fr_open = value;
      store.sa_open = value;
      store.su_open = value;
    }
  },
  closeWorkHour = (value) => {
    const close_times = [store.tu_close, store.we_close, store.th_close, store.fr_close, store.sa_close, store.su_close];
    if (!close_times.every(val => val)) {
      store.tu_close = value;
      store.we_close = value;
      store.th_close = value;
      store.fr_close = value;
      store.sa_close = value;
      store.su_close = value;
    }
  },
  update = () => {
    confirmLoading.value = true;

    /*
    let model = toRaw(store);
    model.payment_options = JSON.stringify(model.payment_options);
    model.default_option = model.default_option - 1;
    model.ask_mode = model.ask_mode ? 1 : 0;
    model.tax = model.tax ? 1 : 0;
     */

    let model = {}
    if (editPhone.value) model.phone = store['phone']
    if (editTelegram.value) model.telegram = store['telegram']
    if (editInstagram.value) model.instagram = store['instagram']
    if (editAvgBill.value) model.avg_bill = store['avg_bill']
    if (editDescription.value) model.description = store['description']
    if (editButtonColor.value) model.button_color = store['button_color']
    if (editCuisines.value) model.cuisines = store['cuisines']
    if (editClassifications.value) model.classifications = store['classifications']
    if (editAdditionals.value) model.additionals = store['additionals']
    if (editStickers.value) model.stickers = store['stickers']
    if (editCashback.value) model.cashback = store['cashback']
    if (editWorkingHours.value) {
      model.day_n_night = store.day_n_night ? 1 : 0;
      model.mo_open = store['mo_open']
      model.mo_close = store['mo_close']
      model.tu_open = store['tu_open']
      model.tu_close = store['tu_close']
      model.we_open = store['we_open']
      model.we_close = store['we_close']
      model.th_open = store['th_open']
      model.th_close = store['th_close']
      model.fr_open = store['fr_open']
      model.fr_close = store['fr_close']
      model.sa_open = store['sa_open']
      model.sa_close = store['sa_close']
      model.su_open = store['su_open']
      model.su_close = store['su_close']
    }
    if (editParameters.value) {
      model.call_waitress = store.call_waitress ? 1 : 0;
      model.call_hookah = store.call_hookah ? 1 : 0;
      model.call_bill = store.call_bill ? 1 : 0;
      model.check_location = store.check_location ? 1 : 0;
      model.scan_enabled = store.scan_enabled ? 1 : 0;
      model.with_payment = store.with_payment ? 1 : 0;
      model.can_reserve = store.can_reserve ? 1 : 0;
      model.show_marketplace = store.show_marketplace ? 1 : 0;
      model.mark = store.mark ? 1 : 0;
    }

    return apiClient.put(`/store/update-all`, {
      ...model,
      ids: props.storeIds,
    }).then(response => {
      if (response) {
        resetForm()
        if (response.data.data) {
          response.data.data.forEach((item) => {
            notification.warning({
              message: `ID: ${item.id}`,
              description: item.details,
            })
          })
        }
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  };

</script>

<style scoped>
@import "./edit.css";
</style>
