<template>
  <a-modal
    title="Вывод чаевых"
    :visible="modalVisible"
    :closable="true"
    @cancel="resetForm"
  >
    <template #footer>
      <a-button key="cancel" @click="resetForm">Отмена</a-button>
      <a-button v-if="!withdrawCreated" key="create" type="primary" :loading="!(!loading && !confirmLoading)" @click="create">Отправить СМС</a-button>
      <a-button v-if="withdrawCreated" key="withdraw" type="primary" :loading="!(!loading && !confirmLoading)" @click="confirm">Вывести</a-button>
    </template>
    <div class="spin-loader" v-if="fetching">
      <a-spin />
    </div>
    <div v-else>
      <div v-if="!withdrawCreated">
        <a-page-header
          :title="storeName"
        />
        <a-form v-model="withdraw" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-item label="Номер карты">
            <a-input
              type="text"
              @input="updateField('card_pan', $event.target.value)"
              v-maska="'#### #### #### ####'"
              :value="withdraw.card_pan.value">
              <template #suffix>
                <CreditCardOutlined />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item label="Сумма">
            <a-input
              type="number"
              suffix="UZS"
              @input="updateField('amount', $event.target.value)"
              :value="withdraw.amount.value" />
          </a-form-item>
        </a-form>
      </div>
      <div v-else>
        <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-item label="Код из СМС">
            <a-input
              type="text"
              v-maska="'####'"
              v-model:value="otp">
              <template #prefix>
                <MessageOutlined />
              </template>
            </a-input>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-modal
      title="Успешно"
      :visible="confirmModalVisible"
      :closable="true"
      @ok="confirmModalOk"
    >
      <div>
        <p>Списано с кошелька: {{ formattedBalance(amount) }} сум</p>
        <p>Комиссия: {{ formattedBalance(commission) }} сум</p>
        <p>Поступило на карту: {{ formattedBalance(received) }} сум</p>
        <p>Остаток на кошельке: {{ formattedBalance(wallet_balance) }} сум</p>
      </div>
    </a-modal>
  </a-modal>
</template>

<script>
import apiClient from "@/services/axios";
import { ref, defineComponent } from 'vue';
import { message } from "ant-design-vue";
import { CreditCardOutlined, MessageOutlined } from "@ant-design/icons-vue"
import {mask} from "maska";

export default defineComponent({
  name: 'VbWithdraw',

  components: {
    CreditCardOutlined,
    MessageOutlined,
  },

  props: {
    storeId: {
      type: Number,
      default: 0,
    },
    storeName: {
      type: String,
      default: '',
    },
  },

  emits: ['hide-modal', 'reload'],

  setup(props, { emit }) {

    let loading = ref(false),
      fetching = ref(true),
      confirmLoading = ref(false),
      confirmModalVisible = ref(false),
      amount = ref(''),
      commission = ref(''),
      received = ref(''),
      wallet_balance = ref(''),
      modalVisible = ref(true),
      labelCol = { span: 7 },
      withdrawId = ref(0),
      withdrawCreated = ref(false),
      otp = ref(''),
      wrapperCol = { span: 15 },
      withdraw = {
        card_pan: ref(''),
        amount: ref(0),
      };

    const
      updateField = (field, value) => {
        withdraw[field].value = value
      },
      resetCreateForm = () => {
        withdraw.card_pan.value = '';
        withdraw.amount.value = 0;
      },
      resetForm = () => {
        otp.value = ''
        modalVisible.value = false;
        emit('hide-modal')
      },
      create = () => {
        confirmLoading.value = true;

        let model = {
          card_pan: withdraw.card_pan.value.replaceAll(' ', ''),
          amount: withdraw.amount.value * 100,
        }

        return apiClient.post(`/store/${props.storeId}/withdraw`, model).then(response => {
          if (response) {
            resetCreateForm()
            let phone = response.data.data.phone
            phone = phone ? mask(phone, '+998(##) ###-##-##') : ''
            withdrawId.value = response.data.data.id
            withdrawCreated.value = true
            message.success(`СМС был отправлен на номер ${phone}`)
          }
        }).catch(() => {
          // console.log(err)
        }).finally(() => {
          confirmLoading.value = false;
        })
      },
      formattedBalance = (balance) => {
        let formatter = new Intl.NumberFormat('RU', {
          style: 'currency',
          currency: 'UZS',
        });
        let formattedBalance = formatter.format(balance / 100)
        return formattedBalance.substring(0, formattedBalance.length - 4)
      },
      confirm = () => {
        confirmLoading.value = true;
        return apiClient.put(`/store/${props.storeId}/withdraw/${withdrawId.value}`, {
          otp: otp.value.replace('-', ''),
        }).then(response => {
          if (response) {
            amount.value = response.data.data.amount;
            commission.value = response.data.data.commission;
            received.value = response.data.data.received;
            wallet_balance.value = response.data.data.wallet_balance;
          }
        }).catch(() => {
          // console.log(err)
        }).finally(() => {
          confirmLoading.value = false;
        })
      },
      confirmModalOk = () => {
        resetForm()
        confirmModalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
      };

    apiClient.get(`/store/${props.storeId}`).then(response => {
      if (response) {
        let { wallet } = response.data.data
        if (wallet && wallet.balance) {
          withdraw.amount.value = wallet.balance / 100
        }
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      fetching.value = false
    })

    return {
      withdraw,
      fetching,
      loading,
      labelCol,
      withdrawId,
      withdrawCreated,
      wrapperCol,
      modalVisible,
      confirmLoading,
      confirmModalVisible,
      wallet_balance,
      amount,
      commission,
      received,
      otp,
      create,
      confirm,
      resetForm,
      formattedBalance,
      confirmModalOk,
      resetCreateForm,
      updateField,
    }
  },
})
</script>

<style scoped>
@import "./withdraw.css";
</style>
