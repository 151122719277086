<template>
  <a-modal
    class="store-edit-modal"
    :title="`Настройки меню: ${props.storeName}`"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <store-map @hide-map="hideMapModal" :coords="store.coordinates" :modal="mapModal" />
    <div v-show="!(!loading && !confirmLoading)" class="w-100 py-5 my-5 text-center">
      <a-spin tip="Загрузка..."></a-spin>
    </div>
    <a-form v-show="!loading && !confirmLoading" v-model="store" @submit.prevent="update">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <a-form-item label="Номер телефона">
                  <a-input
                    @input="updatePhone($event.target.value)"
                    v-model:value="phone"
                    v-maska="'+998(##) ###-##-##'"
                    placeholder="Введите номер телефона"
                  />
                </a-form-item>
                <a-form-item label="Telegram">
                  <a-input
                    placeholder="Пр: rahmatuz"
                    v-model:value="store.telegram"
                  />
                </a-form-item>
                <a-form-item label="Instagram">
                  <a-input
                    placeholder="Пр: rahmatuz"
                    v-model:value="store.instagram"
                  />
                </a-form-item>
                <a-form-item label="Средний чек / Цена за dl. для AliveWater">
                  <a-input
                    v-model:value="store.avg_bill"
                    placeholder="Средний чек"
                    type="number"
                  />
                </a-form-item>
                <a-form-item label="Описание">
                  <a-textarea
                    v-model:value="store.description"
                    placeholder="Описание"
                    :auto-size="{ minRows: 2, maxRows: 6 }"
                  />
                </a-form-item>
                <a-form-item label="Цвет">
                  <div v-if="!showColorPicker">
                    <a-button class="w-100" @click="showColorPicker = true">Редактировать</a-button>
                  </div>
                  <color-picker
                    v-if="showColorPicker"
                    @color-change="onChangeButtonColor"
                    :color="getColor()"
                    alpha-channel="hide"
                    :visible-formats="['hex']"
                  />
                  <a-button
                    type="primary"
                    v-if="showColorPicker"
                    @click="resetColor"
                    class="w-100"
                  >Сбросить
                  </a-button>
                </a-form-item>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <a-form-item label="Адрес филиала">
              <div class="container">
                <div class="row">
                  <div class="col-md-11">
                    <div class="row pr-3">
                      <a-input
                        placeholder="Введите координаты"
                        v-model:value="store.address"
                      >
                        <template #suffix>
                          <a-spin v-if="addressLoading" :indicator="indicator" />
                        </template>
                      </a-input>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <div class="row">
                      <a-button @click="showMapModal" class="w-100">
                        <template #icon>
                          <svg class="my-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                               viewBox="0 0 16 16" fill="none">
                            <path
                              d="M8 1C4.6862 1 2 3.6862 2 7C2 8.6563 2.6711 10.156 3.7565 11.2417C4.8422 12.328 7.4 13.9 7.55 15.55C7.57249 15.7974 7.7516 16 8 16C8.2484 16 8.42751 15.7974 8.45 15.55C8.6 13.9 11.1578 12.328 12.2435 11.2417C13.3289 10.156 14 8.6563 14 7C14 3.6862 11.3138 1 8 1Z"
                              fill="#5a5c97" />
                            <path
                              d="M8.00002 9.10015C9.15982 9.10015 10.1 8.15994 10.1 7.00015C10.1 5.84035 9.15982 4.90015 8.00002 4.90015C6.84023 4.90015 5.90002 5.84035 5.90002 7.00015C5.90002 8.15994 6.84023 9.10015 8.00002 9.10015Z"
                              fill="white" />
                          </svg>
                        </template>
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item label="Кухня и блюда">
              <a-select
                v-model:value="store.cuisines"
                mode="tags"
                :options="cuisines"
                :option-filter-prop="'label'"
                allow-clear
                :max-tag-count="4"
                placeholder="Кухня и блюда"
              >
                <template #option="{ value, label, icon }">
                  <img width="20" :src="icon" :alt="value" />
                  &nbsp;&nbsp;{{ label }}
                </template>
              </a-select>
            </a-form-item>
            <a-form-item label="Тип заведения">
              <a-select
                v-model:value="store.classifications"
                mode="tags"
                :options="classifications"
                :option-filter-prop="'label'"
                allow-clear
                :max-tag-count="4"
                placeholder="Типы заведений"
              >
                <template #option="{ label }">
                  &nbsp;&nbsp;{{ label }}
                </template>
              </a-select>
            </a-form-item>
            <a-form-item label="Дополнительные">
              <a-select
                v-model:value="store.additionals"
                mode="tags"
                :options="additionals"
                :max-tag-count="4"
                :option-filter-prop="'label'"
                allow-clear
                placeholder="Дополнительные"
              >
                <template #option="{ label }">
                  &nbsp;&nbsp;{{ label }}
                </template>
              </a-select>
            </a-form-item>
            <a-form-item label="Стикеры">
              <a-select
                v-model:value="store.stickers"
                mode="tags"
                :options="stickers"
                :max-tag-count="4"
                :option-filter-prop="'label'"
                placeholder="Стикеры"
                allow-clear
              >
                <template #option="{ value, label, icon }">
                  <img width="20" :src="icon" :alt="value" />
                  &nbsp;&nbsp;{{ label }}
                </template>
              </a-select>
            </a-form-item>
            <a-form-item label="Кешбек">
              <a-input
                placeholder="Введите кешбек"
                suffix="%"
                type="number"
                v-model:value="store.cashback"
              />
            </a-form-item>
            <div class="d-flex justify-content-between">
              <p>Часы работы:</p>
              <div class="d-flex justify-content-between">
                <p class="mr-2">Кругл:</p>
                <a-switch
                  v-model:checked="store.day_n_night"
                  un-checked-children="Выкл"
                  checked-children="Вкл"
                >Кругл:
                </a-switch>
              </div>
            </div>
            <a-form-item v-if="!store.day_n_night">
              <div class="container">
                <div class="row pb-3">
                  <div class="col-md-2">
                    <a-button type="text" class="px-0">ПН</a-button>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.mo_open"
                        placeholder="Открытия"
                        @change="openWorkHour"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row px-1">
                      <a-button type="text" class="px-4">до</a-button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.mo_close"
                        placeholder="Закрытия"
                        @change="closeWorkHour"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-md-2">
                    <a-button type="text" class="px-0">ВТ</a-button>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.tu_open"
                        placeholder="Открытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row px-1">
                      <a-button type="text" class="px-4">до</a-button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.tu_close"
                        placeholder="Закрытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-md-2">
                    <a-button type="text" class="px-0">СР</a-button>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.we_open"
                        placeholder="Открытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row px-1">
                      <a-button type="text" class="px-4">до</a-button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.we_close"
                        placeholder="Закрытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-md-2">
                    <a-button type="text" class="px-0">ЧТ</a-button>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.th_open"
                        placeholder="Открытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row px-1">
                      <a-button type="text" class="px-4">до</a-button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.th_close"
                        placeholder="Закрытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-md-2">
                    <a-button type="text" class="px-0">ПТ</a-button>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.fr_open"
                        placeholder="Открытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row px-1">
                      <a-button type="text" class="px-4">до</a-button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.fr_close"
                        placeholder="Закрытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-md-2">
                    <a-button type="text" class="px-0">СБ</a-button>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.sa_open"
                        placeholder="Открытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row px-1">
                      <a-button type="text" class="px-4">до</a-button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.sa_close"
                        placeholder="Закрытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-md-2">
                    <a-button type="text" class="px-0">ВС</a-button>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.su_open"
                        placeholder="Открытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="row px-1">
                      <a-button type="text" class="px-4">до</a-button>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-select
                        v-model:value="store.su_close"
                        placeholder="Закрытия"
                      >
                        <a-select-option v-for="(hour, key) in workingHours" :key="key" :value="hour">{{ hour }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <div class="code-box-meta markdown mt-5">
              <div class="code-box-title">
                <span>Дополнительные параметры</span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Показать кнопку 'Позвать официанта'</div>
                      </template>
                      <a-form-item label="Официант">
                        <a-switch
                          un-checked-children="Скр."
                          checked-children="Пок."
                          v-model:checked="store.call_waitress" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Показать кнопку 'Позвать кальянщика'</div>
                      </template>
                      <a-form-item label="Кальянщик">
                        <a-switch
                          un-checked-children="Скр."
                          checked-children="Пок."
                          v-model:checked="store.call_hookah" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Показать кнопку 'Попросить счет'</div>
                      </template>
                      <a-form-item label="Счет">
                        <a-switch
                          un-checked-children="Скр."
                          checked-children="Пок."
                          v-model:checked="store.call_bill" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Проверка локации</div>
                      </template>
                      <a-form-item label="Локация">
                        <a-switch
                          un-checked-children="Выкл."
                          checked-children="Вкл."
                          v-model:checked="store.check_location" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Разрешить сканировать QR-код на столах</div>
                      </template>
                      <a-form-item label="QR за столом">
                        <a-switch
                          un-checked-children="Выкл."
                          checked-children="Вкл."
                          v-model:checked="store.scan_enabled" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Разрешить оплатить онлайн при заказе за столом</div>
                      </template>
                      <a-form-item label="Оплата за столом">
                        <a-switch
                          un-checked-children="Выкл."
                          checked-children="Вкл."
                          v-model:checked="store.with_payment" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Разрешить бронировать стол</div>
                      </template>
                      <a-form-item label="Брон">
                        <a-switch
                          un-checked-children="Выкл."
                          checked-children="Вкл."
                          v-model:checked="store.can_reserve" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Отображать филиал на странице маркетплейса</div>
                      </template>
                      <a-form-item label="Маркетплейс">
                        <a-switch
                          un-checked-children="Выкл."
                          checked-children="Вкл."
                          v-model:checked="store.show_marketplace" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Включить маркировку</div>
                      </template>
                      <a-form-item label="Маркировка">
                        <a-switch
                          un-checked-children="Выкл."
                          checked-children="Вкл."
                          v-model:checked="store.mark" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title>
                        <div class="text-center">Принять заказ без подтвержение официанта</div>
                      </template>
                      <a-form-item label="Создание заказа">
                        <a-switch
                          un-checked-children="Выкл."
                          checked-children="Вкл."
                          v-model:checked="store.order_confirm" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import { h, reactive, ref, toRaw } from "vue";
import { ColorPicker } from "vue-accessible-color-picker";
import StoreMap from "@/views/store/map";
import apiClient from "@/services/axios";
import Store from "@/models/Store";
import { LoadingOutlined } from "@ant-design/icons-vue";
import axios from "axios";

const props = defineProps({
  storeId: {
    type: Number,
    default: 0
  },
  storeName: {
    type: String,
    default: ""
  },
  posTypes: {
    type: Array,
    default: ref([])
  },
  brands: {
    type: Array,
    default: ref([])
  }
});

const emit = defineEmits(["hide-modal", "reload"]);

let logoUrl = ref(""),
  backgroundUrl = ref(""),
  addressLoading = ref(false),
  loading = ref(true),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  mapModal = ref(false),
  showColorPicker = ref(false),
  companyName = ref(null),
  classifications = ref([]),
  cuisines = ref([]),
  additionals = ref([]),
  stickers = ref([]),
  phone = ref(""),
  spicModel = ref([]),
  codeModel = ref([]),
  store = reactive(new Store({}));

const
  indicator = h(LoadingOutlined, {
    style: {
      fontSize: "16px"
    },
    spin: true
  }),
  workingHours = ref([
    "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30",
    "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
    "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30",
    "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"
  ]),
  getColor = () => {
    return store["button_color"] ?? "#1fb7a4";
  },
  onChangeButtonColor = value => {
    store.button_color = value.cssColor;
  },
  resetColor = () => {
    showColorPicker.value = false;
    store.button_color = "";
  },
  showMapModal = () => {
    mapModal.value = true;
  },
  hideMapModal = (res) => {
    if (res.address)
      store.address = res.address.toString();
    if (res.address_description)
      store.address_description = res.address_description.toString();
    if (res.coordinates)
      store.coordinates = res.coordinates.toString();
    mapModal.value = false;
  },
  resetForm = () => {
    Object.assign(store, new Store({}));
    modalVisible.value = false;
    emit("hide-modal");
  },
  updatePhone = (input) => {
    let x = input
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    phone.value = input;
    store.phone = x[0];
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(store);
    model.payment_options = JSON.stringify(model.payment_options);
    model.tips_options = JSON.stringify(model.tips_options);
    model.default_option = model.default_option - 1;
    model.ask_mode = model.ask_mode ? 1 : 0;
    model.tax = model.tax ? 1 : 0;
    model.day_n_night = model.day_n_night ? 1 : 0;
    // parameters
    model.call_waitress = model.call_waitress ? 1 : 0;
    model.call_hookah = model.call_hookah ? 1 : 0;
    model.call_bill = model.call_bill ? 1 : 0;
    model.check_location = model.check_location ? 1 : 0;
    model.scan_enabled = model.scan_enabled ? 1 : 0;
    model.with_payment = model.with_payment ? 1 : 0;
    model.can_reserve = model.can_reserve ? 1 : 0;
    model.show_marketplace = model.show_marketplace ? 1 : 0;
    model.mark = model.mark ? 1 : 0;
    model.order_confirm = model.order_confirm ? 1 : 0;

    return apiClient.put(`/store/update?id=${props.storeId}`, model).then(response => {
      if (response) {
        resetForm();
      }
    }).catch(() => {
      // console.log(err);
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit("reload");
      emit("hide-modal");
    });
  },
  sortByAlphabet = (type, array) => {
    const result = array.sort((a, b) => {
      const labelA = a.label.toUpperCase(),
            labelB = b.label.toUpperCase();
      return (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0;
    });

    switch (type) {
      case "classifications":
        classifications.value = result;
        return;
      case "cuisines":
        cuisines.value = result;
        return;
      case "additionals":
        additionals.value = result;
        return;
    }
  },
  openWorkHour = (value) => {
    const open_times = [store.tu_open, store.we_open, store.th_open, store.fr_open, store.sa_open, store.su_open];
    if (!open_times.every(val => val)) {
      store.tu_open = value;
      store.we_open = value;
      store.th_open = value;
      store.fr_open = value;
      store.sa_open = value;
      store.su_open = value;
    }
  },
  closeWorkHour = (value) => {
    const close_times = [store.tu_close, store.we_close, store.th_close, store.fr_close, store.sa_close, store.su_close];
    if (!close_times.every(val => val)) {
      store.tu_close = value;
      store.we_close = value;
      store.th_close = value;
      store.fr_close = value;
      store.sa_close = value;
      store.su_close = value;
    }
  }

const formatPhoneNumber = (phone) => {
  const cleaned = ("" + phone).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/);
  if (match)
    return "+" + match[1] + "(" + match[2] + ") " + match[3] + "-" + match[4] + "-" + match[5];
  return null;
};

apiClient.get(`/store/${props.storeId}`).then(response => {
  if (response) {
    sortByAlphabet("classifications", response.data.data.classifications);
    sortByAlphabet("cuisines", response.data.data.cuisines);
    sortByAlphabet("additionals", response.data.data.additionals);
    stickers.value = response.data.data.stickers;
    logoUrl.value = response.data.data.store.logo_url;
    backgroundUrl.value = response.data.data.store.background_url;

    let model = new Store(response.data.data.store);
    model.call_bill = model.call_bill === 1;
    model.call_hookah = model.call_hookah === 1;
    model.call_waitress = model.call_waitress === 1;
    model.check_location = model.check_location === 1;
    model.can_reserve = model.can_reserve === 1;
    model.day_n_night = model.day_n_night === 1;
    model.scan_enabled = model.scan_enabled === 1;
    model.with_payment = model.with_payment === 1;
    model.show_marketplace = model.show_marketplace === 1;
    model.mark = model.mark === 1;
    model.order_confirm = model.order_confirm === 1;

    phone.value = formatPhoneNumber(model.phone);

    Object.assign(store, model);

    const coords = response.data.data.store.coordinates;

    if (!coords)
      return;

    if (store.address && store.address_description)
      return;

    addressLoading.value = true;
    const coords_string = coords.split(",")[1] + "," + coords.split(",")[0];
    const url = "https://geocode-maps.yandex.ru/1.x/?apikey=e9fe7cab-eb17-4480-976c-a9bd1756a5c1&format=json&geocode=" + coords_string;
    axios.get(url)
      .then((res) => {
        const addressResponse = res.data.response.GeoObjectCollection.featureMember[0];
        store.address = addressResponse["GeoObject"]["name"];
        store.address_description = addressResponse["GeoObject"]["description"];
      })
      .finally(() => {
        addressLoading.value = false;
      });

    // window.ymaps.geocode(coords).then((res) => {
    //   const firstGeoObject = res.geoObjects.get(0);
    //   const addressText = firstGeoObject
    //       .getAddressLine()
    //       .replace(firstGeoObject.getCountry() + ", ", "");
    //   if (addressText)
    //     store.address = addressText
    //   addressLoading.value = false
    // })
  }
}).catch(() => {
  // console.log(err);
}).finally(() => {
  loading.value = false;
});

</script>

<style scoped>
@import "./edit.css";
</style>
