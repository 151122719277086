<template>
  <a-modal
    class="store-edit-modal"
    title="Редактировать филиал"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <div v-show="!(!loading && !confirmLoading)" class="w-100 py-5 my-5 text-center">
      <a-spin tip="Загрузка..."></a-spin>
    </div>
    <a-form v-show="!loading && !confirmLoading" v-model="store" @submit.prevent="update">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <div class="logo-upload">
              <a-upload
                name="logo_url"
                list-type="picture-card"
                class="logo-uploader"
                :show-upload-list="false"
                :headers="getHeaders()"
                :action="getUrl"
                :before-upload="beforeUpload"
                @change="uploadLogo"
              >
                <img v-if="logoUrl" :src="logoUrl" alt="avatar" />
                <div v-else>
                  <loading-outlined v-if="loading"></loading-outlined>
                  <PlusOutlined v-else></PlusOutlined>
                  <div class="ant-upload-text">Логотип</div>
                </div>
              </a-upload>
            </div>
            <div class="mb-4 text-center" v-if="logoUrl">
              <a-button @click="removeImage('logo_url')" type="link" danger><CloseCircleOutlined />Удалить логотип</a-button>
            </div>
            <div class="logo-upload">
              <a-upload
                name="background_url"
                list-type="picture-card"
                class="logo-uploader"
                :show-upload-list="false"
                :headers="getHeaders()"
                :action="getUrl"
                :before-upload="beforeUpload"
                @change="uploadBackground"
              >
                <img v-if="backgroundUrl" :src="backgroundUrl" alt="avatar" />
                <div v-else>
                  <loading-outlined v-if="loading"></loading-outlined>
                  <PlusOutlined v-else></PlusOutlined>
                  <div class="ant-upload-text">Фон</div>
                </div>
              </a-upload>
            </div>
            <div class="mb-4 text-center" v-if="backgroundUrl">
              <a-button @click="removeImage('background_url')" type="link" danger><CloseCircleOutlined />Удалить фон</a-button>
            </div>
          </div>
          <div class="col-md-6">
            <a-form-item label="Название филиала">
              <a-input
                ref="branchName"
                placeholder="Введите название филиала"
                v-model:value="store.name"
              />
            </a-form-item>
            <a-form-item label="Варианты оплаты">
              <a-select
                v-model:value="store.payment_options"
                mode="tags"
                :max-tag-count="4"
                placeholder="Варианты оплаты"
                allow-clear
              ></a-select>
            </a-form-item>
            <a-form-item label="Варианты чаевых">
              <a-select
                v-model:value="store.tips_options"
                mode="tags"
                :max-tag-count="4"
                placeholder="Варианты чаевых"
                allow-clear
              ></a-select>
            </a-form-item>
            <a-form-item class="default-option-item">
              <template #label>
                <div class="col-md-12">
                  <div class="row justify-content-between">
                    <div class="float-left">Вариант по умолчанию:</div>
                    <div class="float-right">
                      <a-switch
                        class="float-right"
                        checked-children="Да"
                        un-checked-children="Нет"
                        v-model:checked="store.change_amount"/>
                      <div class="float-right">Ручной ввод:&nbsp;</div>
                    </div>
                  </div>
                </div>
              </template>
              <a-radio-group v-model:value="store.default_option">
                <a-radio-button
                  v-for="key in store.payment_options.length + 1"
                  :value="key"
                  :key="key"
                >{{ key === 1 ? 'Без чаевых' : store.payment_options[key - 2] }}</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="ИНН (ПИНФЛ для ИП)" v-if="companyName">
              <a-input
                disabled
                class="w-100"
                v-model:value="store.tin"
              />
            </a-form-item>
            <a-form-item label="МФО" v-if="companyName">
              <a-input
                disabled
                class="w-100"
                v-model:value="store.mfo"
              />
            </a-form-item>
            <a-form-item label="Расч. счет" v-if="companyName">
              <a-input
                disabled
                class="w-100"
                v-model:value="store.account_no"
              />
            </a-form-item>
            <a-form-item v-if="companyName" label="Название орг.">
              <a-input
                disabled
                class="w-100"
                v-model:value="companyName"
              />
            </a-form-item>
            <a-form-item label="Статус" v-if="companyName">
              <a-input
                disabled
                class="w-100"
                :value="companyStatus ? 'Активный' : 'Неактивный'"
              />
            </a-form-item>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import { PlusOutlined, LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
import { nextTick, onMounted, reactive, ref, toRaw } from 'vue';
import { getBase64 } from "@/helpers/base64";
import { getHeaders } from "@/helpers/header"
import apiClient from "@/services/axios";
import { message } from "ant-design-vue";
import Store from "@/models/Store";

const props = defineProps({
  storeId: {
    type: Number,
    default: 0,
  },
  posTypes: {
    type: Array,
    default: ref([]),
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let logoUrl = ref(''),
  backgroundUrl = ref(''),
  loading = ref(true),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  recipientAccount = ref(null),
  companyName = ref(null),
  companyStatus = ref(null),
  branchName = ref("branchName"),
  store = reactive(new Store({}));

const
  uploadLogo = info => {
    logoUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      store.logo_url = info.file.response.data
      getBase64(info.file['originFileObj'], base64Url => {
        logoUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  uploadBackground = info => {
    backgroundUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      store.background_url = info.file.response.data
      getBase64(info.file['originFileObj'], base64Url => {
        backgroundUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  removeImage = (type) => {
    store[type] = ''
    if (type === 'logo_url')
      logoUrl.value = ''
    else
      backgroundUrl.value = ''

    confirmLoading.value = true;
    apiClient
      .post(`/store/remove-image?id=${props.storeId}&type=${type}`)
      .then(response => {
        if (response) {
          confirmLoading.value = false;
        }
      }).catch(() => {
      confirmLoading.value = false;
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  beforeUpload = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/store/upload'
  },
  resetForm = () => {
    Object.assign(store, new Store({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(store);
    model.payment_options = JSON.stringify(model.payment_options);
    model.tips_options = JSON.stringify(model.tips_options);
    model.default_option = model.default_option - 1;

    return apiClient.put(`/store/update?id=${props.storeId}`, model).then(response => {
      if (response) {
        resetForm()
      }
    }).catch(() => {
        // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  };

apiClient.get(`/store/${props.storeId}`).then(response => {
  if (response) {
    logoUrl.value = response.data.data.store.logo_url
    backgroundUrl.value = response.data.data.store.background_url

    let model = new Store(response.data.data.store);
    model.default_option = parseInt(model.default_option) + 1
    if (model.payment_details) {
      model.tin = model.payment_details.tin_pinfl
      model.mfo = model.payment_details.mfo
      model.account_no = model.payment_details.account_no
    }

    if (typeof model.payment_options == 'string') {
      model.payment_options = JSON.parse(model.payment_options)
    }

    if (typeof model.tips_options == 'string') {
      model.tips_options = JSON.parse(model.tips_options)
    }

    Object.assign(store, model)

    if (response.data.data.store['account']) {
      companyName.value = response.data.data.store['account']['name']
      companyStatus.value = response.data.data.store['account']['active']
    }
  }
}).catch(() => {
  // console.log(err)
}).finally(() => {
  loading.value = false
})

onMounted(() => {
  nextTick(() => branchName.value?.focus())
  window.addEventListener('keydown', (e) => {
    if (e.key === 'Enter' && e.metaKey) {
      update()
    }
  });
})

</script>

<style scoped>
@import "./edit.css";
</style>
