<template>
  <a-modal
    class="store-edit-modal"
    title="Редактировать филиал"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <div v-show="!(!loading && !confirmLoading)" class="w-100 py-5 my-5 text-center">
      <a-spin tip="Загрузка..."></a-spin>
    </div>
    <a-form v-show="!loading && !confirmLoading" v-model="store" @submit.prevent="update">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <div class="logo-upload">
              <a-upload
                name="logo_url"
                list-type="picture-card"
                class="logo-uploader"
                :show-upload-list="false"
                :headers="getHeaders()"
                :action="getUrl"
                :before-upload="beforeUpload"
                @change="uploadLogo"
              >
                <img v-if="logoUrl" :src="logoUrl" alt="avatar" />
                <div v-else>
                  <loading-outlined v-if="loading"></loading-outlined>
                  <PlusOutlined v-else></PlusOutlined>
                  <div class="ant-upload-text">Логотип</div>
                </div>
              </a-upload>
            </div>
            <div class="mb-4 text-center" v-if="logoUrl">
              <a-button @click="removeImage('logo_url')" type="link" danger><CloseCircleOutlined />Удалить логотип</a-button>
            </div>
            <div class="logo-upload">
              <a-upload
                name="background_url"
                list-type="picture-card"
                class="logo-uploader"
                :show-upload-list="false"
                :headers="getHeaders()"
                :action="getUrl"
                :before-upload="beforeUpload"
                @change="uploadBackground"
              >
                <img v-if="backgroundUrl" :src="backgroundUrl" alt="avatar" />
                <div v-else>
                  <loading-outlined v-if="loading"></loading-outlined>
                  <PlusOutlined v-else></PlusOutlined>
                  <div class="ant-upload-text">Фон</div>
                </div>
              </a-upload>
            </div>
            <div class="mb-4 text-center" v-if="backgroundUrl">
              <a-button @click="removeImage('background_url')" type="link" danger><CloseCircleOutlined />Удалить фон</a-button>
            </div>
            <a-form-item label="НДС по умолчанию">
              <a-input
                placeholder="НДС"
                v-model:value="store.default_product_tax"
              />
            </a-form-item>
            <a-form-item label="Отключенные приложения">
              <a-select
                label-in-value
                mode="multiple"
                :options="paymentSystems"
                :option-filter-prop="'label'"
                placeholder="Выберите приложение"
                v-model:value="store.disabled_apps"
              />
            </a-form-item>
            <a-form-item label="Оплата за счет">
              <a-switch
                checked-children="Вкл"
                un-checked-children="Выкл"
                v-model:checked="store.pay_for_bill" />
            </a-form-item>
            <div class="code-box-meta markdown" v-if="store.pay_for_bill">
              <div class="code-box-title">
                <span>Реквизиты оплаты</span>
              </div>
              <a-form-item label="ИНН (ПИНФЛ для ИП)">
                <a-input
                  v-if="recipientAccount"
                  disabled
                  class="w-100"
                  v-model:value="store.tin"
                />
                <a-input
                  v-else
                  class="w-100"
                  v-model:value="store.tin"
                />
              </a-form-item>
              <a-form-item label="МФО">
                <a-input
                  v-if="recipientAccount"
                  disabled
                  class="w-100"
                  v-model:value="store.mfo"
                />
                <a-input
                  v-else
                  class="w-100"
                  v-model:value="store.mfo"
                />
              </a-form-item>
              <a-form-item label="Расч. счет">
                <a-input
                  v-if="recipientAccount"
                  disabled
                  class="w-100"
                  v-model:value="store.account_no"
                />
                <a-input
                  v-else
                  class="w-100"
                  v-model:value="store.account_no"
                />
              </a-form-item>
              <a-form-item v-if="companyName" label="Название орг.">
                <a-input
                  v-if="companyName"
                  disabled
                  class="w-100"
                  v-model:value="companyName"
                />
              </a-form-item>
              <a-form-item label="Статус" v-if="recipientAccount">
                <a-input
                  disabled
                  class="w-100"
                  :value="companyStatus ? 'Активный' : 'Неактивный'"
                />
              </a-form-item>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-9 ant-form-item-label"></div>
                <div class="ant-col ant-col-24 ant-form-item-control-wrapper">
                  <div class="row container my-2" style="gap: 15px">
                    <div class="flex-column">
                      <a-button v-if="recipientAccount" @click="updateMerchantStatus" :disabled="clearLoading" :loading="refreshLoading" type="primary">
                        Обновить статус
                      </a-button>
                    </div>
                    <div class="flex-column">
                      <a-button v-if="recipientAccount" @click="clearRecipientAccount" :disabled="refreshLoading" :loading="clearLoading" type="danger">
                        Очистить
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <a-form-item label="Название филиала">
              <a-input
                ref="branchName"
                placeholder="Введите название филиала"
                v-model:value="store.name"
              />
            </a-form-item>
            <a-form-item label="Бренд">
              <a-select
                ref="brand_id"
                v-model:value="store.brand_id"
                :options="brands"
                :option-filter-prop="'label'"
                show-search
                allow-clear
              >
              </a-select>
            </a-form-item>
            <a-form-item label="Тип кассовой системы">
              <a-select
                ref="pos_type_id"
                v-model:value="store.pos_type_id"
                :options="posTypes"
                allow-clear
              >
              </a-select>
            </a-form-item>
            <a-form-item label="SPOT ID" v-if="isPoster">
              <a-input
                  ref="spot_id"
                  v-model:value="store.spot_id"
              />
            </a-form-item>
            <a-form-item label="Логин сервера" v-if="isPoster || isJowi || isIiko">
              <a-input
                  placeholder="Введите логин"
                  v-model:value="store.server_login"
              />
            </a-form-item>
            <a-typography-link
                v-if="isPoster && store.server_login"
                :href="'https://' + store.server_login + '.joinposter.com/api/auth?application_id=2879&redirect_uri=https://mesh.multicard.uz/tips/callback/poster&response_type=code'"
                :style="{ display: 'block', marginTop: '6px' }"
                target="_blank"
                code
            >Cсылка для авторизации</a-typography-link>
            <a-form-item label="Пароль сервера" v-if="isPoster || isJowi || isIiko">
              <a-input
                  placeholder="Введите пароль"
                  v-model:value="store.server_pass"
              />
            </a-form-item>
            <a-form-item label="URL сервера" v-if="isPoster || isJowi || isIiko">
              <a-input
                  placeholder="Введите URL"
                  v-model:value="store.server_url"
              />
            </a-form-item>
            <a-form-item label="Включить чаевые">
              <a-switch
                checked-children="Вкл"
                un-checked-children="Выкл"
                v-model:checked="store.tips_enabled"
              />
            </a-form-item>
            <div class="code-box-meta markdown" v-if="store.tips_enabled">
              <div class="code-box-title">
                <span>Чаевые</span>
              </div>
              <a-form-item label="Варианты оплаты">
                <a-select
                  v-model:value="store.payment_options"
                  mode="tags"
                  :max-tag-count="4"
                  placeholder="Варианты оплаты"
                  allow-clear
                ></a-select>
              </a-form-item>
              <a-form-item label="Варианты чаевых">
                <a-select
                  v-model:value="store.tips_options"
                  mode="tags"
                  :max-tag-count="4"
                  placeholder="Варианты чаевых"
                  allow-clear
                ></a-select>
              </a-form-item>
              <a-form-item class="default-option-item">
                <template #label>
                  <div class="col-md-12">
                    <div class="row justify-content-between">
                      <div class="float-left">Вариант по умолчанию:</div>
                      <div class="float-right">
                        <a-switch
                          class="float-right"
                          checked-children="Да"
                          un-checked-children="Нет"
                          v-model:checked="store.change_amount"/>
                        <div class="float-right">Ручной ввод:&nbsp;</div>
                      </div>
                    </div>
                  </div>
                </template>
                <a-radio-group v-model:value="store.default_option">
                  <a-radio-button
                    v-for="key in store.payment_options.length + 1"
                    :value="key"
                    :key="key"
                  >{{ key === 1 ? 'Без чаевых' : store.payment_options[key - 2] }}</a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="Настройки доставки">
                <a-select
                  ref="split"
                  v-model:value="store.split"
                >
                  <a-select-option value="with_payment">Оплата с доставкой</a-select-option>
                  <a-select-option value="split_delivery">Доставка отдельно</a-select-option>
                  <a-select-option value="same_page">На одной странице</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item>
                <template #label>
                  <a-spin v-if="walletTypeChecking" :indicator="indicator" size="small" />
                  <span v-else>Тип кошелька</span>
                </template>
                <a-select
                  ref="wallet_type"
                  v-model:value="store.wallet_type"
                  :disabled="walletTypeChecking"
                  @change="editWalletType"
                >
                  <!--                <a-select-option value="p2p">P2P</a-select-option>-->
                  <a-select-option value="personal">Личный</a-select-option>
                  <a-select-option value="shared">Общий</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="Комиссия за чаевые" style="position: relative">
                <a-input
                  class="input-with-suffix"
                  placeholder="Введите комиссию"
                  suffix="%"
                  type="number"
                  v-model:value="store.tips_commission"
                  :disabled="!tipsCommissionEnabled"
                />
                <a-button
                  @click="tipsCommissionEnabled = !tipsCommissionEnabled"
                  type="text"
                  style="position: absolute; right: 0"
                >
                  <template #icon>
                    <EditOutlined v-if="!tipsCommissionEnabled" />
                    <CloseOutlined v-if="tipsCommissionEnabled" />
                  </template>
                </a-button>
              </a-form-item>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <div class="row">
                      <a-tooltip>
                        <template #title><div class="text-center">Запрашивать перед оплатой</div></template>
                        <a-form-item label="Модалка:">
                          <a-switch
                            checked-children="Вкл"
                            un-checked-children="Выкл"
                            v-model:checked="store.ask_mode"
                          />
                        </a-form-item>
                      </a-tooltip>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-tooltip>
                        <template #title><div class="text-center">Коммиссию за чаевые платит гость</div></template>
                        <a-form-item label="Коммиссия:">
                          <a-switch
                            checked-children="Вкл"
                            un-checked-children="Выкл"
                            v-model:checked="store.pay_guest"
                          />
                        </a-form-item>
                      </a-tooltip>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <a-tooltip>
                        <template #title>
                          <div class="text-center">Отображать звездочки и комментарии на странице чаевых</div>
                        </template>
                        <a-form-item label="Рейтинг">
                          <a-switch
                            checked-children="Вкл"
                            un-checked-children="Выкл"
                            v-model:checked="store.show_rating" />
                        </a-form-item>
                      </a-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="code-box-meta markdown mt-5">
                <div class="code-box-title">
                    <span>Дополнительные параметры</span>
                </div>
            </div>
            <a-form-item style="position: relative" class="tips-notification-item">
              <template #label>
                <div class="col-md-12">
                  <div class="row justify-content-between">
                    <div class="float-left">Отзывы:</div>
                    <div class="float-right">
                      <a-switch
                        class="float-right"
                        checked-children="Да"
                        un-checked-children="Нет"
                        v-model:checked="store.tips_notification"/>
                      <div class="float-right">Скрыть сумму:&nbsp;</div>
                    </div>
                  </div>
                </div>
              </template>
              <a-input
                @change="fetchChannelName($event, 'tips')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_tips"
                :disabled="!tipsLoginEnabled"
              />
              <a-button
                @click="tipsLoginEnabled = !tipsLoginEnabled"
                type="text"
                style="position: absolute; right: 0"
              >
                <template #icon>
                  <EditOutlined v-if="!tipsLoginEnabled" />
                  <CloseOutlined v-if="tipsLoginEnabled" />
                </template>
              </a-button>
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="tipsChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px' }">{{ tipsChannelName }}</a-typography-text>
            </a-form-item>
            <a-form-item label="Счета" style="position: relative;">
              <a-input
                @change="fetchChannelName($event, 'bill')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_bill"
                :disabled="!billLoginEnabled"
              />
              <a-button
                @click="billLoginEnabled = !billLoginEnabled"
                type="text"
                style="position: absolute; right: 0"
              >
                <template #icon>
                  <EditOutlined v-if="!billLoginEnabled" />
                  <CloseOutlined v-if="billLoginEnabled" />
                </template>
              </a-button>
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="billChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px'} ">{{ billChannelName }}</a-typography-text>
            </a-form-item>
            <a-form-item label="Бронирование" style="position: relative;">
              <a-input
                @change="fetchChannelName($event, 'reserve')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_reserve"
                :disabled="!reserveLoginEnabled"
              />
              <a-button
                @click="reserveLoginEnabled = !reserveLoginEnabled"
                type="text"
                style="position: absolute; right: 0"
              >
                <template #icon>
                  <EditOutlined v-if="!reserveLoginEnabled" />
                  <CloseOutlined v-if="reserveLoginEnabled" />
                </template>
              </a-button>
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="reserveChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px'} ">{{ reserveChannelName }}</a-typography-text>
            </a-form-item>
            <a-form-item label="Заказы" style="position: relative;">
              <a-input
                @change="fetchChannelName($event, 'order')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_order"
                :disabled="!orderLoginEnabled"
              />
              <a-button
                @click="orderLoginEnabled = !orderLoginEnabled"
                type="text"
                style="position: absolute; right: 0"
              >
                <template #icon>
                  <EditOutlined v-if="!orderLoginEnabled" />
                  <CloseOutlined v-if="orderLoginEnabled" />
                </template>
              </a-button>
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="orderChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px'} ">{{ orderChannelName }}</a-typography-text>
            </a-form-item>
            <a-form-item label="Доставка" style="position: relative;">
              <a-input
                @change="fetchChannelName($event, 'delivery')"
                placeholder="Telegram ID"
                v-model:value="store.login_for_delivery"
                :disabled="!deliveryLoginEnabled"
              />
              <a-button
                @click="deliveryLoginEnabled = !deliveryLoginEnabled"
                type="text"
                style="position: absolute; right: 0"
              >
                <template #icon>
                  <EditOutlined v-if="!deliveryLoginEnabled" />
                  <CloseOutlined v-if="deliveryLoginEnabled" />
                </template>
              </a-button>
              <LoadingOutlined :style="{ color: '#52c41a' }" v-if="deliveryChannelFetching" />
              <a-typography-text v-else type="success" :style="{ display: 'block', marginTop: '6px'} ">{{ deliveryChannelName }}</a-typography-text>
            </a-form-item>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title><div class="text-center">Включить фискализацию</div></template>
                      <a-form-item label="Фискализация:">
                        <a-switch
                          checked-children="Вкл"
                          un-checked-children="Выкл"
                          v-model:checked="store.tax"
                        />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title><div class="text-center">Скрыть лого на гл. странице</div></template>
                      <a-form-item label="Скрыть лого">
                        <a-switch
                          checked-children="Скр."
                          un-checked-children="Пок."
                          v-model:checked="store.hide_logo" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <a-tooltip>
                      <template #title><div class="text-center">Фильтровать по кешдеску (филиалу) в отчетах</div></template>
                      <a-form-item label="Фильтр по кешдеску:">
                        <a-switch
                          checked-children="Вкл"
                          un-checked-children="Выкл"
                          v-model:checked="store.has_cashdesk" />
                      </a-form-item>
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  CloseCircleOutlined, QuestionCircleOutlined,
} from '@ant-design/icons-vue'
import { h, createVNode, nextTick, onMounted, reactive, ref, toRaw, watch } from 'vue'
import { getBase64 } from "@/helpers/base64";
import { getHeaders } from "@/helpers/header"
import apiClient from "@/services/axios";
import { message, Modal } from "ant-design-vue";
import Store from "@/models/Store";
import {debounce} from "lodash";

const props = defineProps({
  storeId: {
    type: Number,
    default: 0,
  },
  posTypes: {
    type: Array,
    default: ref([]),
  },
  brands: {
    type: Array,
    default: ref([]),
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let logoUrl = ref(''),
  backgroundUrl = ref(''),
  loading = ref(true),
  tipsLoginEnabled = ref(false),
  tipsCommissionEnabled = ref(false),
  billLoginEnabled = ref(false),
  reserveLoginEnabled = ref(false),
  orderLoginEnabled = ref(false),
  deliveryLoginEnabled = ref(false),
  clearLoading = ref(false),
  refreshLoading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  isJowi = ref(false),
  isPoster = ref(false),
  isIiko = ref(false),
  recipientAccount = ref(null),
  walletTypeChecking = ref(false),
  companyName = ref(null),
  companyStatus = ref(null),
  branchName = ref("branchName"),
  tipsChannelFetching = ref(false),
  tipsChannelName = ref(''),
  billChannelFetching = ref(false),
  billChannelName = ref(''),
  reserveChannelFetching = ref(false),
  reserveChannelName = ref(''),
  orderChannelFetching = ref(false),
  orderChannelName = ref(''),
  deliveryChannelFetching = ref(false),
  deliveryChannelName = ref(''),
  store = reactive(new Store({})),
  indicator = h(LoadingOutlined, { style: { fontSize: "16px" }, spin: true });

const paymentSystems = ref([
  { label: 'MULTICARD', value: 'multicard' },
  { label: 'PAYME', value: 'payme' },
  { label: 'PAYZE', value: 'payze' },
  { label: 'OSON', value: 'oson' },
  { label: 'CLICK', value: 'click' },
  { label: 'UZUM', value: 'uzum' },
  { label: 'ALIF', value: 'alif' },
  { label: 'XAZNA', value: 'xazna' },
  { label: 'ANORBANK', value: 'anorbank' },
  // { label: 'OCTO', value: 'octo' },
  // { label: 'HUMANS', value: 'humans' },
])

const
  uploadLogo = info => {
    logoUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      store.logo_url = info.file.response.data
      getBase64(info.file['originFileObj'], base64Url => {
        logoUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  uploadBackground = info => {
    backgroundUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      store.background_url = info.file.response.data
      getBase64(info.file['originFileObj'], base64Url => {
        backgroundUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  removeImage = (type) => {
    store[type] = ''
    if (type === 'logo_url')
      logoUrl.value = ''
    else
      backgroundUrl.value = ''

    confirmLoading.value = true;
    apiClient
      .post(`/store/remove-image?id=${props.storeId}&type=${type}`)
      .then(response => {
        if (response) {
          confirmLoading.value = false;
        }
      }).catch(() => {
      confirmLoading.value = false;
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  beforeUpload = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/store/upload'
  },
  resetForm = () => {
    Object.assign(store, new Store({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  clearRecipientAccount = () => {
    recipientAccount.value = null
    store.recipient_account = null
    store.tin = null
    store.account_no = null
    store.mfo = null
    companyName.value = null
    companyStatus.value = null
    // clearLoading.value = true
    // apiClient
    //   .post(`/store/${props.storeId}/clear-recipient-account`)
    //   .then(response => {
    //     if (response.data.data) {
    //       recipientAccount.value = null
    //       store.recipient_account = null
    //       store.tin = null
    //       store.account_no = null
    //       store.mfo = null
    //       companyName.value = null
    //       companyStatus.value = null
    //     }
    //     clearLoading.value = false
    //   }).catch(err => {
    //     console.log(err)
    //     clearLoading.value = false
    //   }).finally(() => clearLoading.value = false)
  },
  updateMerchantStatus = () => {
    refreshLoading.value = true
    apiClient.post(`/store/${props.storeId}/update-merchant-status`).then(response => {
      if (response.data.data) {
        recipientAccount.value = response.data.data.recipient_account
        store.recipient_account = response.data.data.account.recipient_account
        store.tin = response.data.data.account.tin_pinfl
        store.account_no = response.data.data.account.account_no
        store.mfo = response.data.data.account.mfo
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      refreshLoading.value = false
    })
  },
  editWalletType = type => {
    walletTypeChecking.value = true
    apiClient.get(`/store/check-wallet-type?id=${props.storeId}&type=${type}`).then(({ data: response }) => {
      if (response.data?.['confirm_required'] === true) {
        Modal.confirm({
          content: () => response.data.message,
          icon: () => createVNode(QuestionCircleOutlined),
          okText: () => 'Да',
          cancelText: () => 'Отменить',
          onOk() {
            return updateWalletType(type)
          },
          onCancel() {
            Modal.destroyAll();
          }
        });
      } else if (response.data?.['confirm_required'] === false) {
        updateWalletType(type)
      }
    }).finally(() => {
      walletTypeChecking.value = false
    })
  },
  updateWalletType = type => {
    return apiClient.get(`/store/update-wallet-type?id=${props.storeId}&type=${type}`).then(({ data: response }) => {
      if (response.data) {
        message.success("Тип кошелька успешно обновлен")
      }
    })
  },
  fetchChannelName = debounce((event, type) => {
    switch (type) {
      case 'tips':
        tipsChannelFetching.value = true;
        break;
      case 'bill':
        billChannelFetching.value = true;
        break;
      case 'reserve':
        reserveChannelFetching.value = true;
        break;
      case 'order':
        orderChannelFetching.value = true;
        break;
      case 'delivery':
        deliveryChannelFetching.value = true;
        break;
    }

    fetch(`https://api.telegram.org/bot1886153513:AAFI8UfPx4bnD1FSwWWwolQYhlnrRvRm74Y/getChat?chat_id=${event.target.value}`)
      .then(response => response.json())
      .then((res) => {
        switch (type) {
          case 'tips':
            tipsChannelFetching.value = false
            tipsChannelName.value = (res.ok) ? res.result.title : ''
            break;
          case 'bill':
            billChannelFetching.value = false
            billChannelName.value = (res.ok) ? res.result.title : ''
            break;
          case 'reserve':
            reserveChannelFetching.value = false
            reserveChannelName.value = (res.ok) ? res.result.title : ''
            break;
          case 'order':
            orderChannelFetching.value = false
            orderChannelName.value = (res.ok) ? res.result.title : ''
            break;
          case 'delivery':
            deliveryChannelFetching.value = false
            deliveryChannelName.value = (res.ok) ? res.result.title : ''
            break;
        }
      })
  }, 300),
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(store);
    model.payment_options = JSON.stringify(model.payment_options);
    model.tips_options = JSON.stringify(model.tips_options);
    model.default_option = model.default_option - 1;
    model.ask_mode = model.ask_mode ? 1 : 0;
    model.tax = model.tax ? 1 : 0;
    model.tips_enabled = model.tips_enabled ? 1 : 0;
    model.tips_notification = model.tips_notification ? 1 : 0;

    return apiClient.put(`/store/update?id=${props.storeId}`, model).then(response => {
      if (response) {
        resetForm()
      }
    }).catch(() => {
        // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  };

apiClient.get(`/store/${props.storeId}`).then(response => {
  if (response) {
    logoUrl.value = response.data.data.store.logo_url
    backgroundUrl.value = response.data.data.store.background_url

    let model = new Store(response.data.data.store);
    model.brand_id = model.brand_id ? model.brand_id.toString() : null
    model.default_option = parseInt(model.default_option) + 1
    model.pay_for_bill = model.pay_for_bill === 1
    model.show_rating = model.show_rating === 1
    model.pay_guest = model.pay_guest === 1
    model.change_amount = model.change_amount === 1
    model.has_cashdesk = model.has_cashdesk === 1
    model.ask_mode = model.ask_mode === 1
    model.tax = model.tax === 1
    model.tips_enabled = model.tips_enabled === 1
    model.tips_notification = model.tips_notification === 1
    if (model.pos_type_id)
      model.pos_type_id = model.pos_type_id.toString()
    if (model.spot_id)
      model.spot_id = model.spot_id.toString()

    recipientAccount.value = model.recipient_account
    if (model.payment_details) {
      model.tin = model.payment_details.tin_pinfl
      model.mfo = model.payment_details.mfo
      model.account_no = model.payment_details.account_no
    }

    if (typeof model.payment_options == 'string') {
      model.payment_options = JSON.parse(model.payment_options)
    }

    if (typeof model.tips_options == 'string') {
      model.tips_options = JSON.parse(model.tips_options)
    }

    if (model.disabled_apps) {
      let apps = []
      model.disabled_apps.forEach(app => {
        const system = paymentSystems.value.find(a => a.value === app)
        if (system) {
          apps.push(system)
        }
      })
      model.disabled_apps = apps
    }

    Object.assign(store, model)

    if (response.data.data.store['account']) {
      companyName.value = response.data.data.store['account']['name']
      companyStatus.value = response.data.data.store['account']['active']
    }
  }
}).catch(() => {
  // console.log(err)
}).finally(() => {
  loading.value = false
})

watch(() => {
  const posType = props.posTypes[store['pos_type_id']];
  if (posType) {
    isJowi.value = posType.label === 'JOWI'
    isPoster.value = posType.label === 'Poster'
    isIiko.value = posType.label === 'IIKO'
  } else {
    isJowi.value = false
    isPoster.value = false
    isIiko.value = false
  }
})

onMounted(() => {
  nextTick(() => branchName.value?.focus())
  window.addEventListener('keydown', (e) => {
    if (e.key === 'Enter' && e.metaKey) {
      update()
    }
  });
})

</script>

<style scoped>
@import "./edit.css";
</style>
